import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function PostList({ posts }) {
  console.log("Post list")
  console.log(posts)
  if (posts.length == 0) {
    return <div>No post found!</div>
  }
  return (
      <div className="max-w-3xl">
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          return (
            <div
              key={title}
              className="flex flex-col gap-x-7 rounded-xl py-4 transition-all duration-500 md:flex-row"
            >
              <div className=" place-self-center md:h-20 md:w-20">
                <GatsbyImage
                  alt={title}
                  image={getImage(post.frontmatter.cover)!}
                  className=" h-full md:h-20 md:w-20 md:rounded-3xl xl:rounded-3xl"
                  objectPosition="50% 50%"
                />
              </div>

              <div className="flex w-full flex-col px-5 py-5 xl:px-0 xl:py-0">
                <Link to={post.fields.slug}>
                  <div className="text-2xl font-bold hover:underline ">
                    {title}
                  </div>
                </Link>
                <div className="flex gap-5 text-sm md:flex-row">
                  {/* <div>{post.frontmatter.category}</div> */}
                  <div>{post.frontmatter.date}</div>
                  <div>{post.timeToRead} phút đọc</div>
                  <div className="flex flex-row gap-5">
                    {post.frontmatter.tags.map(tag => {
                      return (
                        <div className="rounded-xl bg-gray-200 px-3">{tag}</div>
                      )
                    })}
                  </div>
                </div>
                <div className="pt-2">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description" 
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
  )
}

PostList.propTypes = {
  posts: PropTypes.array,
}

export default PostList
